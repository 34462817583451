/* This example requires Tailwind CSS v2.0+ */
import { useAppContext } from '../../store/app-context';
import { ProjectLabels } from '../../interfaces/Project.types';
import LogoSVGs from '../../constants/LogoSVGs';

export default function SideMenuLogo(): JSX.Element {
  const { currentProject } = useAppContext();
  const prjLabel = currentProject?.prj_label ?? ProjectLabels.WE_KNOW;

  return LogoSVGs[prjLabel];
}
