import { MenuIcon } from '@heroicons/react/outline';

import { useEffect } from 'react';
import { useAppContext } from '../store/app-context';
import WeKnowShapingStars from '../assets/img/WeKnow_GIF_3.gif';

function Community(): JSX.Element {
  const { appData, isMobile, updateHeaderTitle } = useAppContext();

  // on mount effect
  useEffect(() => {
    updateHeaderTitle('WeKnow Home');
    document.body.classList.add('body-home');
    return () => {
      document.body.classList.remove('body-home');
    };
  }, []);

  return (
    <div className="relative">
      {!isMobile && (
        <iframe
          // eslint-disable-next-line
          src={appData.settings.yammerFeed}
          title="Yammer feed"
          id="yammer-iframe"
          style={{
            border: 0,
            overflow: 'auto',
            width: '100%',
            height: 'calc(100vh - 60px)',
          }}
        />
      )}
      {isMobile && (
        <div
          className="flex align-middle justify-center items-center px-4"
          style={{ height: '50vh' }}>
          <div>
            <div className="text-center z-0 relative">
              <img
                src={WeKnowShapingStars}
                className="max-w-md w-full"
                alt="WeKnow Shaping Stars"
              />
            </div>
            <div
              className="text-center text-xl mb-2 relative z-10"
              style={{ top: '-30px' }}>
              <span className="font-bold">Welcome to WeKnow!</span>
              <br />
              <span className="font-normal">
                Click on the{' '}
                <MenuIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                  style={{ display: 'inline-block', margin: '0 2px' }}
                />{' '}
                menu icon to explore.
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Community;
